import React from 'react';

const Tos = () => {
  return (
    <div className="container">
      <div className="row">
        <h2>TÉRMINOS DE USO Y CONDICIONES DE APPRENDE ONLINE</h2>
        <p>
          <strong>1. Aceptación de los Términos:</strong> Al acceder y utilizar los servicios ofrecidos por Apprende Online ("Nosotros", "Nuestro" o "Apprende Online"), aceptas cumplir con estos Términos de Uso y Condiciones (los "Términos"). Si no estás de acuerdo con alguno de estos términos, te instamos a que no utilices nuestros servicios.
        </p>
        <p>
          <strong>2. Uso de los Servicios:</strong>
          <span>
            <strong>2.1.</strong> Apprende Online proporciona servicios de cursos en línea a través de su plataforma. Estos servicios están sujetos a tu conformidad con los presentes Términos.
          </span>
          <span>
            <strong>2.2.</strong> Te comprometes a utilizar los servicios solo con fines legales y de acuerdo con todas las leyes y regulaciones aplicables.
          </span>
        </p>
        <p>
          <strong>3. Registro de la Cuenta:</strong>
          <span>
            <strong>3.1.</strong> Para acceder a ciertos servicios, es posible que debas registrarte y crear una cuenta. La información proporcionada durante el registro debe ser precisa y actualizada, a parte de ser real y legal.
          </span>
          <span>
            <strong>3.2.</strong> Eres responsable de mantener la confidencialidad de tu cuenta y contraseña. Cualquier actividad realizada desde tu cuenta será tu responsabilidad.
          </span>
        </p>

        <p>
          <strong>4. Contenido del Usuario:</strong>
          <span>
            <strong>4.1.</strong> Al enviar contenido a la plataforma, garantizas que tienes los derechos necesarios sobre dicho contenido y que no viola los derechos de terceros ni ninguna ley aplicable.
          </span>
          <span>
            <strong>4.2.</strong> Apprende Online se reserva el derecho de moderar y eliminar contenido que viole estos Términos.
          </span>
        </p>

        <p>
          <strong>5. Pagos y Facturación:</strong>
          <span>
            <strong>5.1.</strong> Al realizar pagos a través de nuestra plataforma, aceptas proporcionar información precisa y completa de facturación.
          </span>
          <span>
            <strong>5.2.</strong> Apprende Online utiliza Wompi como plataforma de procesamiento de pagos. La información financiera se maneja de acuerdo con las políticas de seguridad de la pasarela de pagos.
          </span>
        </p>

        <p>
          <strong>6. Cancelación y Reembolsos:</strong>
          <span>
            <strong>6.1.</strong> Las políticas de cancelación y reembolso se detallan en nuestra sección correspondiente. Te instamos a revisar estas políticas antes de realizar una compra.
          </span>
        </p>

        <p>
          <strong>7. Propiedad Intelectual:</strong>
          <span>
            <strong>7.1.</strong> Todo el contenido en la plataforma, incluidos cursos, materiales y diseño, está protegido por derechos de autor y otras leyes de propiedad intelectual.
          </span>
          <span>
            <strong>7.2.</strong> No tienes permiso para copiar, distribuir, modificar, transmitir o usar de otra manera cualquier contenido de Apprende Online sin el consentimiento previo por escrito.
          </span>
        </p>

        <p>
          <strong>8. Modificaciones de los Términos:</strong>
          <span>
            <strong>8.1.</strong> Apprende Online se reserva el derecho de modificar estos Términos en cualquier momento. Las modificaciones entrarán en vigencia inmediatamente después de su publicación en la plataforma.
          </span>
        </p>

        <p>
          <strong>9. Terminación del Servicio:</strong>
          <span>
            <strong>9.1.</strong> Apprende Online se reserva el derecho de suspender o terminar tu acceso a los servicios en cualquier momento y por cualquier motivo, sin previo aviso.
          </span>
        </p>

        <p>
          <strong>10. Contacto:</strong> Si tienes alguna pregunta sobre estos Términos de Uso y Condiciones, puedes contactarnos a través de [soporte@cloudsoft.site].
        </p>
      </div>
    </div>
  );
};

export default Tos;
